import { useState } from 'react'
import './App.css'

function App() {
	const [on, setOn] = useState(false)

	return (
		<div
			className='App'
			style={{
				padding: 20,
			}}
		>
			<button onClick={() => setOn(prev => !prev)}>Проверка</button>
			{on && <p>Проверка работает</p>}
		</div>
	)
}

export default App
